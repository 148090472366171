<template>
  <div id="sidebar" :class="['sidebar-nav fade']" :style="{ width: $store.state.collapse ? '200px' : '60px' }">
    <div class="sidebar-nav-inner modal-dialog h-100">
      <div class="sidebar-menu flex-fill">
        <div class="sidebar-scroll">
          <div class="btn-anime1" v-if="isShow">
            <a href="javascript:" class="btn btn-primary btn-lg rounded-6" rel="nofollow" @click="openSubmitProductModal"
              ><i class="iconfont icon-tougao mr-2"></i>
				<span v-if="isIos == 'Android'">四季导航</span>
				<span v-else>四季导航</span>
			  </a
            >
          </div>
          <div class="sidebar-menu-inner">
            <ul class="slide-bar-ul">
              <li
                :class="['sidebar-item', active == item.id ? 'active' : '']"
                v-for="(item, key) in navMenuListNew"
                :key="item.id"
                @click="menuHandle(item)"
              >
                <div v-if="!isShow" class="tooltipBox" :style="{ top: key * 45 + 20 + key + 'px' }">{{ item.name }}</div>
                <a href="javascript:;" :class="'a' + key">
                  <img v-if="item.image" class="iconImg" :src="item.image" />
                  <i v-else :class="item.icon || 'io io-VIP'" />
                  <span v-show="isShow">{{ item.name }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="py-2">
        <div class="flex-bottom">
          <ul>
            <li class="sidebar-item">
              <a href="#" @click="goAbout">
                <i class="iconfont icon-tishi" title="关于我们" />
                <span v-show="isShow">关于我们</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <InquiryGuidance :inquiry-guidance="dialogVisible" @destruction="destruction"></InquiryGuidance>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixins/commonMixin'
import slideBar from './slideBar.vue'
import InquiryGuidance from './InquiryGuidance.vue'
export default {
  components: { slideBar, InquiryGuidance },
  mixins: [commonMixin],
  props: {
    showGoback: {
      type: Boolean,
      default: true
    },
    navMenuList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    isShow() {
      return this.$store.state.collapse || this.$store.state.isDrawer
    },
    navMenuListNew() {
      let arr = []
      if (this.showGoback) {
        arr = [
          {
            id: 'goback',
            name: '返回上级',
            ref: 'goback',
            icon: 'iconfont icon-back'
          }
        ].concat(this.navMenuList)
      } else {
        arr = this.navMenuList
      }
      return arr
    }
  },
  data() {
    return {
      active: 1,
      name: '',
      dialogVisible: false,
	  isIos:''
    }
  },
  mounted() {
	  this.isIos = localStorage.getItem('keyTitle')
  },
  methods: {
    // 锚点
    menuHandle(item) {
      this.active = item.id
      if (item.name == '返回上级') {
        this.$router.go(-1)
        this.$store.commit('toggleDrawer', false)
      } else {
        this.scrollToAnchor(item)
      }
    },
    goAbout() {
      this.$router.push('/about')
    },
    openSubmitProductModal() {
      this.dialogVisible = true
    },
    destruction(val) {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less">
.iconImg {
  max-width: 25px;
  max-height: 25px;
  display: block;
}
.tooltipBox {
  color: #515c6b;
  white-space: nowrap;
  position: fixed;
  top: 0;
  left: 60px;
  z-index: 1081;
  padding: 8px 10px;
  background: #dadbdd;
  transition: all 0.3s;
  border-radius: 5px;
  min-width: 92px;
  display: none;
  &:before {
    content: '';
    border-width: 5px 5px 5px 0;
    border-style: solid;
    position: absolute;
    top: 14px;
    left: -4px;
    border-color: transparent #dadbdd transparent;
  }
}
.el-popup-parent--hidden {
  .btn-anime1 {
    display: block !important;
  }
}
</style>
<style scoped>
.sidebar-nav {
  height: calc(100vh - 60px);
  .sidebar-nav-inner .flex-fill {
    padding-top: 14px;
  }
}
.sidebar-item {
  height: 45px;
  cursor: pointer;
  i {
    font-size: 20px;
  }
  span {
    padding-left: 8px;
  }
  &:hover {
    .tooltipBox {
      display: block !important;
    }
  }
}
.sidebar-item:hover a,
.sidebar-item.active a {
  color: #262b33;
  background-color: #f4f5f6;
}
.sidebar-nav-inner .sidebar-item a {
  color: #515c6b;
}
.btn-anime1 {
  width: 126px;
  margin-left: 25%;
  margin-bottom: 10px;
  display: none;
}
</style>
